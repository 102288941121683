import { Button } from "@/components/ui/button.tsx";
import {
    Sheet,
    SheetContent,
    SheetDescription,
    SheetHeader,
    SheetTitle,
    SheetTrigger,
} from "@/components/ui/sheet.tsx";
import { Menu } from "lucide-react";
import {
    VerticalNavigationMenu,
    VerticalNavigationMenuCollapsibleItem,
    VerticalNavigationMenuContent,
    VerticalNavigationMenuItem,
    VerticalNavigationMenuLink,
    VerticalNavigationMenuList,
    VerticalNavigationMenuTrigger,
    VerticalNavigationMenuIndicator,
} from "@/components/ui/vertical-navigation-menu.tsx";
import { Link } from "@inertiajs/react";
import {
    routes,
    PublicOnly,
    AuthOnly,
} from "@/components/layout/desktop-navigation-menu.tsx";

export function MobileNavigationMenu({ className }: { className?: string }) {
    const url = window.location.href;

    return (
        <div className={className}>
            <Sheet>
                <SheetTrigger asChild>
                    <Button variant="outline" size="icon">
                        <Menu />
                    </Button>
                </SheetTrigger>
                <SheetContent
                    onOpenAutoFocus={(e) => e.preventDefault()}
                    className="w-[300px] flex flex-col space-y-2"
                >
                    <SheetHeader>
                        <SheetTitle>Menu</SheetTitle>
                        <SheetDescription className="sr-only">
                            Menu
                        </SheetDescription>
                    </SheetHeader>
                    <VerticalNavigationMenu>
                        <VerticalNavigationMenuList>
                            <AuthOnly>
                                <VerticalNavigationMenuItem>
                                    <VerticalNavigationMenuLink
                                        active={routes.dashboard === url}
                                        asChild
                                    >
                                        <Link href={routes.dashboard}>
                                            Dashboard
                                            <VerticalNavigationMenuIndicator />
                                        </Link>
                                    </VerticalNavigationMenuLink>
                                </VerticalNavigationMenuItem>
                                <VerticalNavigationMenuItem>
                                    <VerticalNavigationMenuLink
                                        active={routes.profile === url}
                                        asChild
                                    >
                                        <Link href={routes.profile}>
                                            Profile
                                            <VerticalNavigationMenuIndicator />
                                        </Link>
                                    </VerticalNavigationMenuLink>
                                </VerticalNavigationMenuItem>
                                <VerticalNavigationMenuCollapsibleItem>
                                    <VerticalNavigationMenuTrigger
                                        active={
                                            [
                                                routes.assessment.overview,
                                                routes.assessment.history,
                                                routes.assessment.create,
                                            ].some((route) => route === url) ||
                                            url.includes(
                                                "/lifestyle-medicine-assessment",
                                            )
                                        }
                                    >
                                        Assessment
                                        <VerticalNavigationMenuIndicator />
                                    </VerticalNavigationMenuTrigger>
                                    <VerticalNavigationMenuContent>
                                        <VerticalNavigationMenuItem>
                                            <VerticalNavigationMenuLink
                                                active={
                                                    routes.assessment
                                                        .overview === url
                                                }
                                                asChild
                                            >
                                                <Link
                                                    href={
                                                        routes.assessment
                                                            .overview
                                                    }
                                                >
                                                    New
                                                    <VerticalNavigationMenuIndicator />
                                                </Link>
                                            </VerticalNavigationMenuLink>
                                        </VerticalNavigationMenuItem>
                                        <VerticalNavigationMenuItem>
                                            <VerticalNavigationMenuLink
                                                active={
                                                    routes.assessment
                                                        .history === url
                                                }
                                                asChild
                                            >
                                                <Link
                                                    href={
                                                        routes.assessment
                                                            .history
                                                    }
                                                >
                                                    History
                                                    <VerticalNavigationMenuIndicator />
                                                </Link>
                                            </VerticalNavigationMenuLink>
                                        </VerticalNavigationMenuItem>
                                    </VerticalNavigationMenuContent>
                                </VerticalNavigationMenuCollapsibleItem>
                                <VerticalNavigationMenuItem>
                                    <VerticalNavigationMenuLink asChild>
                                        <Link
                                            href={routes.logout}
                                            method="post"
                                            as="button"
                                        >
                                            Log out
                                        </Link>
                                    </VerticalNavigationMenuLink>
                                </VerticalNavigationMenuItem>
                            </AuthOnly>
                            <PublicOnly>
                                <VerticalNavigationMenuItem>
                                    <VerticalNavigationMenuLink
                                        active={routes.login === url}
                                        asChild
                                    >
                                        <Link href={routes.login}>
                                            Log in
                                            <VerticalNavigationMenuIndicator />
                                        </Link>
                                    </VerticalNavigationMenuLink>
                                </VerticalNavigationMenuItem>
                            </PublicOnly>
                        </VerticalNavigationMenuList>
                    </VerticalNavigationMenu>
                </SheetContent>
            </Sheet>
        </div>
    );
}
