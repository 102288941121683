import * as React from "react";
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { cva } from "class-variance-authority";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";

const NavigationMenu = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => {
    return (
        <NavigationMenuPrimitive.Root
            ref={ref}
            orientation="horizontal"
            className={cn(
                "relative z-10 flex items-center justify-center",
                className,
            )}
            {...props}
        >
            {children}
        </NavigationMenuPrimitive.Root>
    );
});
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
    <NavigationMenuPrimitive.List
        ref={ref}
        className={cn(
            "flex flex-1 list-none items-center justify-center space-x-1",
            className,
        )}
        {...props}
    />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Item>
>(({ className, ...props }, ref) => {
    return (
        <NavigationMenuPrimitive.Item
            ref={ref}
            className={cn("relative", className)}
            {...props}
        >
            {props.children}
        </NavigationMenuPrimitive.Item>
    );
});
NavigationMenuItem.displayName = NavigationMenuPrimitive.Item.displayName;

const navigationMenuTriggerStyle = cva(
    "inline-flex h-10 w-full items-center justify-center rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors focus:bg-accent focus:text-accent-foreground hover:bg-accent hover:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[state=open]:bg-accent",
);

const NavigationMenuTrigger = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> & {
        active?: boolean;
    }
>(({ className, children, active, ...props }, ref) => {
    return (
        <NavigationMenuPrimitive.Trigger
            ref={ref}
            className={cn(
                navigationMenuTriggerStyle(),
                "group/trigger",
                className,
            )}
            data-active={active}
            {...props}
        >
            {children}
            <ChevronDown
                className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]/trigger:rotate-180"
                aria-hidden="true"
            />
        </NavigationMenuPrimitive.Trigger>
    );
});
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

const NavigationMenuContent = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content> & {
        alignment?: "start" | "end";
    }
>(({ className, alignment = "start", children, ...props }, ref) => (
    <NavigationMenuPrimitive.Content
        ref={ref}
        className={cn(
            "p-1 min-w-[150px] w-auto flex flex-col top-full absolute mt-1.5 overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-lg ",
            "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90",
            {
                "left-0": alignment === "start",
                "right-0": alignment === "end",
            },
            className,
        )}
        {...props}
    >
        <ul>{children}</ul>
    </NavigationMenuPrimitive.Content>
));
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

const NavigationMenuLink = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Link>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ className, active, ...props }, ref) => {
    return (
        <NavigationMenuPrimitive.Link
            ref={ref}
            active={active}
            {...props}
            className={cn(
                navigationMenuTriggerStyle(),
                "relative group/link",
                className,
            )}
        />
    );
});
NavigationMenuLink.displayName = NavigationMenuPrimitive.Link.displayName;

const NavigationMenuContentLink = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Link>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ className, ...props }, ref) => {
    return (
        <NavigationMenuPrimitive.Link
            ref={ref}
            {...props}
            className={cn(
                navigationMenuTriggerStyle(),
                "justify-start relative group/link data-[active]:bg-accent data-[active]:text-accent-foreground",
                className,
            )}
        />
    );
});

NavigationMenuContentLink.displayName = "NavigationMenuContentLink";

function NavigationMenuIndicator() {
    return (
        <div className="z-10 absolute flex flex-row justify-center items-center bottom-0 right-0 left-0">
            <div className="h-0.5 bg-primary rounded-full transition-all w-0 group-data-[active]/link:w-1/2 group-data-[active=true]/trigger:w-1/2"></div>
        </div>
    );
}

export {
    navigationMenuTriggerStyle,
    NavigationMenu,
    NavigationMenuList,
    NavigationMenuItem,
    NavigationMenuContent,
    NavigationMenuTrigger,
    NavigationMenuLink,
    NavigationMenuContentLink,
    NavigationMenuIndicator,
};
