import { Link } from "@inertiajs/react";
import { DesktopNavigationMenu } from "@/components/layout/desktop-navigation-menu.tsx";
import { MobileNavigationMenu } from "@/components/layout/mobile-navigation-menu.tsx";

export function Header() {
    return (
        <header className="flex p-1 px-2 md:px-20 bg-card shadow z-10 space-x-5">
            <div className="flex flex-row space-x-2 items-center">
                <div className="w-28">
                    <Link href={route("home")} target="_blank">
                        <img src="/assets/logo.svg" alt="logo" />
                    </Link>
                </div>
            </div>
            <div className="flex flex-row flex-1 items-center justify-end">
                <DesktopNavigationMenu className="hidden md:flex" />
                <MobileNavigationMenu className="flex md:hidden" />
            </div>
        </header>
    );
}
