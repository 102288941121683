import * as React from "react";
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import {
    Collapsible,
    CollapsibleTrigger,
    CollapsibleContent,
} from "@/components/ui/collapsible.tsx";
import { cn } from "@/lib/utils.ts";
import { cva } from "class-variance-authority";
import { ChevronDown } from "lucide-react";

const VerticalNavigationMenu = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => {
    return (
        <NavigationMenuPrimitive.Root
            ref={ref}
            orientation="vertical"
            className={cn(
                "relative z-10 flex flex-1 flex-col min-h-0 overflow-y-auto",
                className,
            )}
            {...props}
        >
            {children}
        </NavigationMenuPrimitive.Root>
    );
});
VerticalNavigationMenu.displayName = "VerticalNavigationMenu";

const VerticalNavigationMenuList = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.List>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
    <NavigationMenuPrimitive.List
        ref={ref}
        className={cn(
            "flex flex-col flex-1 list-none justify-start items-center space-y-1",
            className,
        )}
        {...props}
    />
));
VerticalNavigationMenuList.displayName = "VerticalNavigationMenuList";

const VerticalNavigationMenuItem = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Item>
>(({ className, ...props }, ref) => {
    return (
        <NavigationMenuPrimitive.Item
            ref={ref}
            className={cn("relative w-full", className)}
            {...props}
        >
            {props.children}
        </NavigationMenuPrimitive.Item>
    );
});

VerticalNavigationMenuItem.displayName = "VerticalNavigationMenuItem";

const navigationMenuTriggerStyle = cva(
    "relative inline-flex h-10 w-full items-center justify-start rounded-md bg-background px-4 py-2 text-sm font-medium transition-colors focus:bg-accent focus:text-accent-foreground hover:bg-accent hover:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50",
);

const VerticalNavigationMenuLink = React.forwardRef<
    React.ElementRef<typeof NavigationMenuPrimitive.Link>,
    React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Link>
>(({ className, ...props }, ref) => {
    return (
        <NavigationMenuPrimitive.Link
            ref={ref}
            {...props}
            className={cn(
                navigationMenuTriggerStyle(),
                "relative group/link",
                className,
            )}
        />
    );
});
VerticalNavigationMenuLink.displayName = "VerticalNavigationMenuLink";

const VerticalNavigationMenuCollapsibleItem = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <li className="relative w-full">
            <Collapsible>{children}</Collapsible>
        </li>
    );
};

VerticalNavigationMenuCollapsibleItem.displayName =
    "VerticalNavigationMenuCollapsibleItem";

const VerticalNavigationMenuTrigger = ({
    children,
    asChild,
    className,
    active,
}: {
    children: React.ReactNode;
    className?: string;
    asChild?: boolean;
    active?: boolean;
}) => {
    return (
        <CollapsibleTrigger
            asChild={asChild}
            className={cn(
                "flex flex-row group/trigger",
                navigationMenuTriggerStyle(),
                className,
            )}
            data-active={active}
        >
            <div className="flex flex-1">{children}</div>

            <ChevronDown
                className="relative top-[1px] ml-1 h-3 w-3 transition duration-200 group-data-[state=open]/trigger:rotate-180"
                aria-hidden="true"
            />
        </CollapsibleTrigger>
    );
};
VerticalNavigationMenuTrigger.displayName = "VerticalNavigationMenuTrigger";

const VerticalNavigationMenuContent = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) => {
    return (
        <CollapsibleContent
            className={cn(
                "overflow-hidden transition-all data-[state=open]:animate-collapse-down data-[state=closed]:animate-collapse-up",
                className,
            )}
        >
            <ul className="pl-4">{children}</ul>
        </CollapsibleContent>
    );
};
VerticalNavigationMenuContent.displayName = "VerticalNavigationMenuContent";

function VerticalNavigationMenuIndicator() {
    return (
        <div className="z-10 absolute flex flex-row justify-center items-center left-0 top-0 bottom-0">
            <div className="w-0.5 bg-primary rounded-full transition-all h-0 group-data-[active]/link:h-1/2 group-data-[active=true]/trigger:h-1/2"></div>
        </div>
    );
}

export {
    VerticalNavigationMenu,
    VerticalNavigationMenuList,
    VerticalNavigationMenuItem,
    VerticalNavigationMenuLink,
    VerticalNavigationMenuCollapsibleItem,
    VerticalNavigationMenuTrigger,
    VerticalNavigationMenuContent,
    VerticalNavigationMenuIndicator,
};
