import { ReactNode } from "react";
import { Header } from "@/components/layout/header.tsx";
import { Footer } from "@/components/layout/footer.tsx";

export function RootLayout({ children }: { children: ReactNode }) {
    return (
        <div className="h-screen bg-accent flex flex-col">
            <Header />
            <div
                id="root-layout-scroll-container"
                className="flex flex-col flex-1 relative min-h-0 overflow-auto "
            >
                <div className="container relative flex-1 pt-5 pb-10">
                    {children}
                </div>
                <Footer />
            </div>
        </div>
    );
}
