import { usePage, Link } from "@inertiajs/react";
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuContentLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    NavigationMenuIndicator,
} from "@/components/ui/navigation-menu";
import type { PageProps } from "@/types/app.d.ts";
import { ReactNode } from "react";

export function AuthOnly({ children }: { children: ReactNode }) {
    const page = usePage<PageProps>();

    if (!page.props.auth.user) {
        return null;
    }

    return children;
}

export function PublicOnly({ children }: { children: ReactNode }) {
    const page = usePage<PageProps>();

    if (page.props.auth.user) {
        return null;
    }

    return children;
}

export const routes = {
    dashboard: route("dashboard.show"),
    profile: route("profile.show"),
    assessment: {
        overview: route("lifestyle-medicine-assessment.overview.show"),
        history: route("lifestyle-medicine-assessment.assessments.index"),
        create: route("lifestyle-medicine-assessment.assessments.create"),
    },
    logout: route("logout"),
    login: route("login"),
};

export function DesktopNavigationMenu({ className }: { className?: string }) {
    const url = window.location.href;

    return (
        <div className={className}>
            <NavigationMenu>
                <NavigationMenuList>
                    <AuthOnly>
                        <NavigationMenuItem>
                            <NavigationMenuLink
                                active={routes.dashboard === url}
                                asChild
                            >
                                <Link href={routes.dashboard}>
                                    Dashboard
                                    <NavigationMenuIndicator />
                                </Link>
                            </NavigationMenuLink>
                        </NavigationMenuItem>
                        <NavigationMenuItem>
                            <NavigationMenuLink
                                active={routes.profile === url}
                                asChild
                            >
                                <Link href={routes.profile}>
                                    Profile
                                    <NavigationMenuIndicator />
                                </Link>
                            </NavigationMenuLink>
                        </NavigationMenuItem>
                        <NavigationMenuItem>
                            <NavigationMenuTrigger
                                active={
                                    [
                                        routes.assessment.overview,
                                        routes.assessment.history,
                                        routes.assessment.create,
                                    ].some((route) => route === url) ||
                                    url.includes(
                                        "/lifestyle-medicine-assessment",
                                    )
                                }
                            >
                                Assessment
                                <NavigationMenuIndicator />
                            </NavigationMenuTrigger>
                            <NavigationMenuContent>
                                <NavigationMenuItem>
                                    <NavigationMenuContentLink
                                        active={
                                            routes.assessment.overview === url
                                        }
                                        asChild
                                    >
                                        <Link href={routes.assessment.overview}>
                                            New
                                        </Link>
                                    </NavigationMenuContentLink>
                                </NavigationMenuItem>
                                <NavigationMenuItem>
                                    <NavigationMenuContentLink
                                        active={
                                            routes.assessment.history === url
                                        }
                                        asChild
                                    >
                                        <Link href={routes.assessment.history}>
                                            History
                                        </Link>
                                    </NavigationMenuContentLink>
                                </NavigationMenuItem>
                            </NavigationMenuContent>
                        </NavigationMenuItem>
                        <NavigationMenuItem>
                            <NavigationMenuLink asChild>
                                <Link
                                    href={routes.logout}
                                    method="post"
                                    as="button"
                                >
                                    Log out
                                </Link>
                            </NavigationMenuLink>
                        </NavigationMenuItem>
                    </AuthOnly>
                    <PublicOnly>
                        <NavigationMenuItem>
                            <NavigationMenuLink
                                active={routes.login === url}
                                asChild
                            >
                                <Link href={routes.login}>
                                    Log in
                                    <NavigationMenuIndicator />
                                </Link>
                            </NavigationMenuLink>
                        </NavigationMenuItem>
                    </PublicOnly>
                </NavigationMenuList>
            </NavigationMenu>
        </div>
    );
}
