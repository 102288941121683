export function Footer() {
    return (
        <footer>
            <div>
                <svg
                    className="relative top-1 block w-full h-[35px]"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path
                        d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                        className="fill-[#63987c]"
                    ></path>
                </svg>
            </div>
            <div className="flex flex-1 flex-col space-y-1 bg-[#63987c] text-white text-center text-xs p-5">
                <div className="text-primary">
                    <a
                        href="/terms-of-service"
                        className="underline font-bold mr-1"
                        target="_blank"
                    >
                        Terms of Service
                    </a>
                    <span className="mr-1"> | </span>
                    <a
                        href="/privacy-policy"
                        className="underline font-bold"
                        target="_blank"
                    >
                        Privacy Policy
                    </a>
                </div>
                <div>Copyright © 2024 Apasas Health</div>
            </div>
        </footer>
    );
}
